import custom_axios from "../axios";
export const get_store_sync_status = async (id, formData) => {
  try {
    const response = await custom_axios.get(`/shopify/store/`);
    return response.data;
  } catch (error) {
    console.error("Failed to find store sync info:", error);
    return true;
  }
};
