import React, { useState, useEffect, useCallback, useMemo } from "react";
import Icon from "../../../components/Icon";
import useDarkMode from "use-dark-mode";
import cn from "classnames";
import styles from "./OrderLineItemsIndex.module.sass";
import Card from "../../../components/Card";

const OrderLineItems = ({ orderData, onSelectionChange, scannedBarcode }) => {
  const [products, setProducts] = useState([]);
  const [pendingPackedProducts, setPendingPackedProducts] = useState([]);
  const darkMode = useDarkMode(false);

  useEffect(() => {
    if (orderData && orderData.LineItems) {
      const initialProducts = orderData.LineItems.map((item) => ({
        id: item?.id ?? `null`,
        product_id: item?.product_id ?? null,
        variant_id: item?.variant_id ?? null,
        title: item?.name ?? "Unknown Product",
        sku: item?.sku ?? "N/A",
        barcode: item?.product_variant?.barcode ?? "N/A",
        location: item?.product_variant?.variant_location ?? "N/A",
        quantity: parseInt(item?.quantity ?? "0", 10),
        packedQuantity: 0,
        product: {
          id: item?.product?.id ?? null,
          product_id: item?.product?.product_id ?? null,
          product_type: item?.product?.product_type ?? "Unknown",
          ProductImage: item?.product?.ProductImage ?? [],
        },
        product_variant: {
          ...(item?.product_variant ?? {}),
        },
        image:
          item?.product?.ProductImage?.[0]?.src ?? "/api/placeholder/100/100",
      }));

      setProducts(initialProducts);
      setPendingPackedProducts([]);
    } else {
      setProducts([]);
      setPendingPackedProducts([]);
    }
  }, [orderData]);

  useEffect(() => {
    if (scannedBarcode) {
      setProducts((currentProducts) =>
        currentProducts.map((product) => {
          if (
            product.product_variant?.sku === scannedBarcode &&
            product.packedQuantity < product.quantity
          ) {
            return {
              ...product,
              packedQuantity: Math.min(
                product.packedQuantity + 1,
                product.quantity
              ),
            };
          }
          return product;
        })
      );
    }
  }, [scannedBarcode]);
  const handleCompletePack = useCallback(() => {
    setProducts((currentProducts) =>
      currentProducts.map((product) => ({
        ...product,
        packedQuantity: product.quantity,
      }))
    );
    const completedPackedProducts = products
      .filter((product) => product.packedQuantity === product.quantity)
      .map((product) => ({
        product_id: product.product.id,
        variant_id: product.product_variant?.id,
        is_packed: true,
        packed_count: product.quantity,
        total_count: product.quantity,
      }));
    onSelectionChange(completedPackedProducts);
  }, [products, onSelectionChange]);
  const handleItemPack = useCallback((product) => {
    setProducts((currentProducts) =>
      currentProducts.map((p) =>
        p.id === product.id
          ? {
              ...p,
              packedQuantity: Math.min(p.packedQuantity + 1, p.quantity),
            }
          : p
      )
    );
    setPendingPackedProducts((prevPendingProducts) => [
      ...prevPendingProducts,
      {
        product_id: product.product.id,
        variant_id: product.product_variant?.id,
        is_packed: product.packedQuantity === product.quantity,
        packed_count: product.packedQuantity,
        total_count: product.quantity,
      },
    ]);
  }, []);

  const packedProducts = useMemo(() => {
    return products
      .filter((product) => product.packedQuantity > 0)
      .map((product) => ({
        product_id: product.product.id,
        variant_id: product.product_variant?.id,
        is_packed: product.packedQuantity === product.quantity,
        packed_count: product.packedQuantity,
        total_count: product.quantity,
      }));
  }, [products]);

  useEffect(() => {
    onSelectionChange(pendingPackedProducts);
  }, [pendingPackedProducts, onSelectionChange]);

  const handleQuantityChange = useCallback((id, increment) => {
    setProducts((currentProducts) =>
      currentProducts.map((product) =>
        product.id === id
          ? {
              ...product,
              packedQuantity: Math.max(
                0,
                Math.min(product.packedQuantity + increment, product.quantity)
              ),
            }
          : product
      )
    );
  }, []);

  const getStatusIcon = useCallback((product) => {
    if (product.packedQuantity === 0) {
      return <Icon name="itemsclock" size="24" fill="#6F767E" />;
    }
    if (product.packedQuantity < product.quantity) {
      return <Icon name="pending" size="24" fill="#FF8A00" />;
    }
    return <Icon name="packed" size="24" fill="#39A66A" />;
  }, []);

  const sortedProducts = useMemo(() => {
    return [...products].sort((a, b) => {
      if (a.packedQuantity === a.quantity && b.packedQuantity !== b.quantity)
        return 1;
      if (b.packedQuantity === b.quantity && a.packedQuantity !== a.quantity)
        return -1;
      return 0;
    });
  }, [products]);

  return (
    <>
      <Card
        className={styles.card}
        title="Items"
        classTitle="title-purple"
        head={
          orderData && (
            <button
              className={cn("button", styles.button)}
              onClick={handleCompletePack}
            >
              Complete Pack
            </button>
          )
        }
      >
        {!orderData ? (
          <div>Scan product(s) to see the items here</div>
        ) : (
          <div className="space-y-2">
            {sortedProducts.map((product) => (
              <div
                key={product.id}
                className={`flex items-center pt-0 pb-[10px] px-[21px] rounded-[20px] ${
                  product.packedQuantity === product.quantity
                    ? "bg-[#B5E4CA5E]"
                    : product.packedQuantity > 0
                    ? "bg-[#FFF3E1]"
                    : ""
                }`}
              >
                <div className="flex-shrink-0 mr-3">
                  {getStatusIcon(product)}
                </div>
                <img
                  src={product.image}
                  alt={product.title}
                  className="w-10 h-10 object-cover rounded mr-3"
                />
                <div className="flex-grow">
                  <h3
                    className={`font-semibold ${
                      darkMode.value &&
                      ((product.packedQuantity > 0 &&
                        product.packedQuantity < product.quantity) ||
                        product.packedQuantity === product.quantity)
                        ? "text-black"
                        : ""
                    }`}
                  >
                    {product.title}
                  </h3>

                  <div className="grid grid-cols-4 gap-2 text-sm text-gray-600">
                    <div>
                      <div className="font-semibold">
                        <span className={cn(styles.LineItems)}>SKU</span>
                      </div>
                      <div>{product.product_variant.sku || "N/A"}</div>
                    </div>
                    <div>
                      <div className="font-semibold">
                        <span className={cn(styles.LineItems)}>Barcode</span>
                      </div>
                      <div>
                        {product.product_variant.barcode
                          ? product.product_variant.barcode
                          : "-"}
                      </div>
                    </div>
                    <div>
                      <div className="font-semibold">
                        <span className={cn(styles.LineItems)}>Location</span>
                      </div>
                      <div>
                        {product.product_variant.variant_location || "N/A"}
                      </div>
                    </div>
                    <div className="flex gap-2 items-center">
                      <span className="mr-2 whitespace-nowrap">
                        {product.packedQuantity} / {product.quantity}
                      </span>
                      <div
                        className="inline-flex rounded-md shadow-sm"
                        role="group"
                      >
                        <button
                          onClick={() => handleQuantityChange(product.id, -1)}
                          className={`px-2 py-2 text-sm font-medium ${
                            darkMode.value
                              ? "text-[black] hover:border border-[black]-200"
                              : "text-gray-900"
                          } bg-white border border-gray-200 rounded-l-lg hover:bg-gray-100  focus:z-10 focus:text-blue-700`}
                          disabled={product.packedQuantity === 0}
                        >
                          <Icon name="minus" size="16" fill="#6F767E" />
                        </button>
                        <button
                          onClick={() => handleItemPack(product)}
                          className={`px-2 py-2 text-sm font-medium ${
                            darkMode.value
                              ? "text-[black] hover:border border-[black]-200"
                              : "text-gray-900"
                          } bg-white border border-gray-200 rounded-r-lg hover:bg-gray-100  focus:z-10 focus:text-blue-700`}
                          disabled={product.packedQuantity === product.quantity}
                        >
                          <Icon name="add" size="16" fill="#6F767E" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </Card>
    </>
  );
};

export default OrderLineItems;
