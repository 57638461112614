import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Product.module.sass";
import OrderImageIndex from "./OrderImageIndex";
import AccessControl from "../../../HOC/AccessControl";
import { FETCH_ORDER_DETAILS } from "../../../API/Scanner";

const Product = ({
  className,
  withoutCheckbox,
  initialOrderMedia,
  orderNumber,
  handleRemoveAll,
  handleDelete,
  mediaUploaded,
  setMediaUploaded,
}) => {
  const [orderMedia, setOrderMedia] = useState(initialOrderMedia);

  useEffect(() => {
    if (mediaUploaded) {
      fetchUpdatedOrderMedia();
    }
  }, [mediaUploaded, orderNumber]);

  const fetchUpdatedOrderMedia = async () => {
    try {
      const response = await FETCH_ORDER_DETAILS(orderNumber);
      if (response.status === 200) {
        setOrderMedia(response.data.data.OrderMedia);
      }
    } catch (error) {
      console.error("Error fetching updated order media:", error);
    } finally {
      setMediaUploaded(false);
    }
  };

  const handleRemoveAllLocal = async () => {
    try {
      await handleRemoveAll();
      setOrderMedia([]); // Update local state
    } catch (error) {
      console.error("Error removing all media:", error);
    }
  };

  const handleDeleteLocal = async (mediaId) => {
    try {
      await handleDelete(mediaId);
      setOrderMedia((prevMedia) =>
        prevMedia.filter((media) => media.id !== mediaId)
      );
    } catch (error) {
      console.error("Error deleting media:", error);
    }
  };

  // Function to pair up media items
  const pairMediaItems = (mediaItems) => {
    const pairs = [];
    for (let i = 0; i < mediaItems.length; i += 2) {
      pairs.push(mediaItems.slice(i, i + 2));
    }
    return pairs;
  };

  const mediaPairs = pairMediaItems(orderMedia || []);

  return (
    <div className={cn(styles.product, className)}>
      <div className={styles.head}>
        <div className={cn("title-green", styles.title)}>
          <div>Recently Captured</div>
          <div className="flex gap-7">
            <div className="text-xs leading-4 font-semibold text-gray-600">
              {
                orderMedia?.filter((media) => media.media_type === "image")
                  .length
              }{" "}
              Images
            </div>
            <div className="text-xs leading-4 font-semibold text-gray-600">
              {
                orderMedia?.filter((media) => media.media_type === "video")
                  .length
              }{" "}
              Videos
            </div>
          </div>
        </div>
        {orderMedia?.length > 0 && (
          <AccessControl moduleCode="8FZN9w" requiredPrivilege="esufk7">
            <div onClick={handleRemoveAllLocal}>
              <button
                className={cn("button-stroke button-small", styles.button)}
              >
                <span>Remove All</span>
              </button>
            </div>
          </AccessControl>
        )}
      </div>
      <div className={styles.mediaContainer}>
        {mediaPairs.map((pair, pairIndex) => (
          <React.Fragment key={pairIndex}>
            {pair.map((media, index) => (
              <OrderImageIndex
                className={styles.mediaItem}
                item={media}
                key={index}
                handleDelete={() => handleDeleteLocal(media.id)}
                withoutCheckbox={withoutCheckbox}
              />
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Product;
