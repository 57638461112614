import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useLocation } from "react-router-dom";
import moment from "moment";
import cn from "classnames";
import { GENERATE_PICKLIST_BY_PRODUCT } from "../../../../API/Orders";
import styles from "./GeneratePickListByOrderPrintView.module.sass";
import Barcode from "react-barcode";
import Tag from "../../../../assets/images/tag.svg";
import CustomToast from "../../../../components/CustomToast";
import { addPrintTags } from "../../../../API/Orders";
const GeneratePickListByProductPrintView = () => {
  const showToast = CustomToast();
  const [data, setData] = useState(null);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const printComponentRef = useRef();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const checkedItems = location.state?.checkedItems || {};
  const itemIds = Object.keys(checkedItems);

  const handleAddPrintTag = async () => {
    try {
      const order_id_list = itemIds.map(Number);
      const res = await addPrintTags(order_id_list);
      if (res.status === 200) {
        showToast(res.data.message, "success");
      }
    } catch (err) {
      console.error("err", err);
      showToast("Failed to add tags", "error");
    }
  };
  // const location = useLocation();
  // const searchParams = new URLSearchParams(location.search);
  // const ids = searchParams.get("ids");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GENERATE_PICKLIST_BY_PRODUCT(itemIds);
        setData(response.data || null);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    // Hide the text element within the barcode
    const barcodes = document.querySelectorAll(".barcode");
    barcodes.forEach((barcode) => {
      const texts = barcode.querySelectorAll("text");
      texts.forEach((text) => (text.style.display = "none"));
    });
  }, [data]);

  const formatDateTime = (date) => {
    return moment(date).format("MM-DD-YYYY, hh:mm:ss A");
  };

  const formatName = (name) => {
    if (!name) return "";
    return name.split("_")[0].charAt(0).toUpperCase() + name.slice(1);
  };

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    documentTitle: "Picklist",
    pageStyle: "@page { size: A4; margin: 20mm; }",
  });

  if (!data) {
    return <div>Loading...</div>;
  }

  const { store, lineItems, total_quantity, total_line_items } = data;

  return (
    <div className={styles.scrollable_content}>
      <div className="flex flex-col items-center justify-center">
        <div className="flex  items-center justify-center">
          <button
            className={cn(
              "button",
              styles.button,
              "bg-blue-500 hover:bg-blue-700 text-white font-bold my-4"
            )}
            onClick={handleAddPrintTag}
          >
            Add PickList Tag
          </button>
          <button
            className={cn(
              "button",
              styles.button,
              "bg-blue-500 hover:bg-blue-700 text-white font-bold my-4 ml-3"
            )}
            onClick={handlePrint}
          >
            Print
          </button>
        </div>
        <div>
          <div ref={printComponentRef}>
            <div className="border border-gray-300 rounded-md mb-4 p-4">
              <div className="flex justify-center mb-4">
                {store.store_logo ? (
                  <img
                    src={store.store_logo}
                    alt="Store Logo"
                    className="w-42 h-22"
                  />
                ) : (
                  <p>No Store Logo</p>
                )}
              </div>

              <div className="flex justify-between mb-2">
                <div className="sm-full w:w-1/2 p-4">
                  <p>
                    <strong>Printed On:</strong>
                  </p>
                  <p className={styles.subtext}>
                    {formatDateTime(currentDateTime)}
                  </p>
                  {/* <p>
                  <strong>Delivery/Courier Charges</strong>
                  <span className="text-[15px] -mt-[50px]">
                    (Online Payment/Prepaid)
                    <strong>: N/A</strong>
                  </span>
                </p>
                <p>
                  <strong>Shipping Address:</strong>
                </p>
                <p className="text-[20px] font-semibold">N/A</p>
                <p>N/A</p> */}
                </div>
                {/* <div className="sm-full w:w-1/2 p-4"> */}
                {/* <p>
                  <strong>Created At:</strong>
                </p>
                <p>N/A</p> */}
                {/* <div className="flex gap-[10px]"> */}
                {/* <h1 className="text-xl">
                    <strong>N/A</strong>
                  </h1> */}
                {/* <p>
                    <span className="bg-[#0D6EFD] border border-[#0D6EFD] text-white p-[5px] text-[12px] rounded-[7px]">
                      paid
                    </span>
                  </p> */}
                {/* <p>
                    <span className="bg-[#FFC107] border border-[#FFC107] text-white p-[5px] text-[12px] rounded-[7px]">
                      N/A
                    </span>
                  </p> */}
                {/* </div> */}
                {/* <Barcode
                  value={"N/A"}
                  width={2}
                  height={50}
                  fontSize={12}
                  margin={5}
                  className="barcode"
                />
                <p className="mt-[10px]">
                  <strong>Total orders :</strong>
                  N/A
                </p> */}
                {/* </div> */}
              </div>
              {/* <div className="sm-full w:w-1/2 p-4 flex gap-[10px]">
              <div>N/A</div>
              <div>Total Line of items: {total_line_items}</div>
            </div> */}
              <div className="mt-4">
                <table className="w-full border-collapse">
                  <thead>
                    <tr className="border-b border-gray-300">
                      <th className="border-b border-gray-300 p-[8px]">Qty</th>
                      <th className="border-b border-gray-300 p-[8px]">
                        Image
                      </th>
                      <th className="border-b border-gray-300 p-[8px]">Barcode</th>
                      <th className="border-b border-gray-300 p-[8px]">
                        Order Number
                      </th>
                      <th className="border-b border-gray-300 p-[8px]">Item</th>
                      <th className="border-b border-gray-300 p-[8px]">
                        Weight
                      </th>
                      <th className="border-b border-gray-300 p-[8px]">
                        Price
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {lineItems.map((item, index) => (
                      <tr className="border-b border-gray-300" key={index}>
                        <td
                          style={{ alignContent: "center" }}
                          className="border-b border-gray-300 p-2 text-center"
                        >
                          {item.quantity} x
                        </td>
                        <td
                          style={{ alignContent: "center" }}
                          className="border-b border-gray-300 p-2 text-center "
                        >
                          {item.product &&
                            item.product.ProductImage &&
                            item.product.ProductImage.length > 0 && (
                              <img
                                className="w-12 h-12"
                                src={item.product.ProductImage[0].src}
                                alt={item.name}
                              />
                            )}
                        </td>
                        <td
                          style={{ alignContent: "center" }}
                          className="border-b border-gray-300 p-2 text-center"
                        >
                          <div>
                            <div>{item.variant.sku}</div>
                            <div className="flex justify-center items-center">
                              {item.variant.barcode ? (
                                <Barcode
                                  value={item.variant.barcode}
                                  width={2}
                                  height={50}
                                  fontSize={10}
                                  margin={3}
                                  className="barcode"
                                />
                              ) : null}
                            </div>
                            <div>
                              <p style={{ fontSize: "12px" }}>
                                Location:{" "}
                                {item.product?.location
                                  ? item.product?.location
                                  : "NA"}
                              </p>
                              <p style={{ fontSize: "12px" }}>
                                Variant Location:{" "}
                                {item.variant?.variant_location
                                  ? item.variant?.variant_location
                                  : "NA"}
                              </p>
                            </div>
                          </div>
                        </td>{" "}
                        <td
                          style={{ alignContent: "center" }}
                          className="border-b border-gray-300 p-2 text-center"
                        >
                          {item.order_numbers}
                        </td>
                        <td
                          style={{ alignContent: "center" }}
                          className="border-b border-gray-300 p-2 text-center"
                        >
                          {item.name}
                          <p className={styles.subtext}>{item.product?.product_type}</p>
                        </td>
                        <td
                          style={{ alignContent: "center" }}
                          className="border-b border-gray-300 p-2 text-center"
                        >
                          {parseFloat((item.grams * item.quantity) / 1000)} kg
                        </td>
                        <td
                          style={{ alignContent: "center" }}
                          className="border-b border-gray-300 p-2 text-center"
                        >
                          {item.price}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* <div className="flex justify-between mb-1 mt-7">
              <div className="sm-full w:w-1/2 p-4">
                <p>
                  <strong>Total Quantities : </strong> {total_quantity}
                </p>
                <p>
                  Order Weight:{" "}
                  {parseFloat(
                    lineItems.reduce(
                      (acc, item) => acc + item.grams * item.quantity,
                      0
                    ) / 1000
                  )}{" "}
                  Kgs + Box Weight 90 grams ={" "}
                  {parseFloat(
                    (lineItems.reduce(
                      (acc, item) => acc + item.grams * item.quantity,
                      0
                    ) +
                      90) /
                      1000
                  )}{" "}
                  Kgs
                </p>
                <p>
                  <strong>Order value : </strong> N/A
                </p>
              </div>
              <div className="sm-full w:w-1/2 p-4">
                <p>
                  <strong>Assigned to : </strong> N/A
                </p>
                <p>
                  <strong>Printed By : </strong> N/A
                </p>
                <p>
                  <strong>Assigned by : </strong> N/A
                </p>
              </div>
            </div> */}
              {/* <div className="sm-full w:w-1/2 p-4 flex flex-col justify-center items-center">
              <img src={Tag} alt="" className="w-[200px]" />
              <h1 className="text-[20px] font-bold">PKG ID: N/A</h1>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneratePickListByProductPrintView;

// import React, { useEffect, useRef, useState } from "react";
// import { useReactToPrint } from "react-to-print";
// import { useLocation } from "react-router-dom";
// import moment from "moment";
// import cn from "classnames";
// import { GENERATE_PICKLIST_BY_PRODUCT } from "../../../../API/Orders";
// import styles from "./GeneratePickListByOrderPrintView.module.sass";
// import Barcode from "react-barcode";
// import Tag from "../../../../assets/images/tag.svg";
// const GeneratePickListByProductPrintView = () => {
//   const [data, setData] = useState([]);
//   const [orders, setOrders] = useState([]);
//   const [currentDateTime, setCurrentDateTime] = useState(new Date());
//   const printComponentRef = useRef();
//   const location = useLocation();
//   const searchParams = new URLSearchParams(location.search);
//   const ids = searchParams.get("ids");

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await GENERATE_PICKLIST_BY_PRODUCT(ids);
//         setData(response.data || []);
//         setOrders(response.data.orders || []);
//       } catch (error) {
//         console.error("Error fetching orders:", error);
//       }
//     };

//     fetchData();
//   }, [ids]);

//   useEffect(() => {
//     const timerId = setInterval(() => {
//       setCurrentDateTime(new Date());
//     }, 1000);

//     return () => clearInterval(timerId);
//   }, []);

//   useEffect(() => {
//     // Hide the text element within the barcode
//     const barcodes = document.querySelectorAll(".barcode");
//     barcodes.forEach((barcode) => {
//       const texts = barcode.querySelectorAll("text");
//       texts.forEach((text) => (text.style.display = "none"));
//     });
//   }, [data]);

//   const formatDateTime = (date) => {
//     return moment(date).format("MM-DD-YYYY, hh:mm:ss A");
//   };
//   const formatName = (name) => {
//     if (!name) return "";
//     return name.split("_")[0].charAt(0).toUpperCase() + name.slice(1);
//   };

//   const handlePrint = useReactToPrint({
//     content: () => printComponentRef.current,
//     documentTitle: "Picklist",
//     pageStyle: "@page { size: auto; margin: 20mm; }",
//   });

//   return (
//     <div className="flex flex-col items-center justify-center">
//       <button
//         className={cn(
//           "button",
//           styles.button,
//           "bg-blue-500 hover:bg-blue-700 text-white font-bold my-4"
//         )}
//         onClick={handlePrint}
//       >
//         Print
//       </button>

//       <div className="w-[80%]">
//         <div ref={printComponentRef}>
//           {orders.map((order) => (
//             <div
//               key={order.id}
//               className="border border-gray-300 rounded-md mb-4 p-4"
//             >
//               <div className="flex justify-center mb-4">
//                 <img
//                   src={data.store.store_logo}
//                   alt="Store Logo"
//                   className="w-42 h-22"
//                 />
//               </div>

//               <div className="flex justify-between mb-2">
//                 <div className="sm-full w:w-1/2 p-4">
//                   <p>
//                     <strong>Printed On:</strong>
//                   </p>
//                   <p>{formatDateTime(currentDateTime)}</p>
//                   <p>
//                     <strong>Delivery/Courier Charges</strong>
//                     <span className="text-[15px] -mt-[50px]">
//                       (Online Payment/Prepaid)
//                       <strong> : {order.ShippingLine[0]?.price}</strong>
//                     </span>
//                   </p>
//                   <p>
//                     <strong>Shipping Address:</strong>
//                   </p>
//                   <p className="text-[20px] font-semibold">
//                     {order.ShippingAddress[0]?.first_name}
//                     {order.ShippingAddress[0]?.last_name}
//                   </p>
//                   <p>
//                     {order.ShippingAddress[0]?.address1
//                       ? `${order.ShippingAddress[0]?.address1} ,`
//                       : null}
//                   </p>
//                   <p>
//                     {order.ShippingAddress[0]?.address2
//                       ? `${order.ShippingAddress[0]?.address2},`
//                       : null}
//                   </p>
//                   <p>
//                     {order.ShippingAddress[0]?.zip
//                       ? `${order.ShippingAddress[0]?.zip},`
//                       : null}
//                   </p>
//                   <p>
//                     {order.ShippingAddress[0]?.city},{" "}
//                     {order.ShippingAddress[0]?.province},{" "}
//                     {order.ShippingAddress[0]?.country},
//                   </p>
//                 </div>
//                 <div className="sm-full w:w-1/2 p-4">
//                   <p>
//                     <strong>Created At:</strong>
//                   </p>
//                   <p>
//                     {moment(order.created_at).format("MM-DD-YYYY, hh:mm:ss A")}
//                   </p>
//                   {""}
//                   <div className="flex gap-[10px]">
//                     <h1 className="text-xl">
//                       <strong>{order.order_number}</strong>
//                     </h1>
//                     <p>
//                       <span className="bg-[#0D6EFD] border border-[#0D6EFD] text-white p-[5px] text-[12px] rounded-[7px]">
//                         paid
//                       </span>
//                     </p>
//                     <p>
//                       <span className="bg-[#FFC107] border border-[#FFC107] text-white p-[5px] text-[12px] rounded-[7px]">
//                         {order.financial_status}
//                       </span>
//                     </p>
//                   </div>
//                   <Barcode
//                     value={order.order_number}
//                     width={2}
//                     height={50}
//                     fontSize={12}
//                     margin={5}
//                     className="barcode"
//                   />
//                   <p className="mt-[10px]">
//                     <strong>Total orders :</strong>
//                     {order.total_orders_by_customer}
//                   </p>
//                 </div>
//               </div>
//               <div className="sm-full w:w-1/2 p-4 flex gap-[10px]">
//                 <div> {order.fulfillment_status}</div>
//                 <div>Total Line of items : {order.total_line_items}</div>
//               </div>
//               <div className="mt-4">
//                 <table className="w-full border-collapse">
//                   <thead>
//                     <tr className="border-b border-gray-300">
//                       <th className="border-b border-gray-300 p-[8px]">Qty</th>
//                       <th className="border-b border-gray-300 p-[8px]">
//                         Image
//                       </th>
//                       <th className="border-b border-gray-300 p-[8px]">SKU</th>
//                       <th className="border-b border-gray-300 p-[8px]">Item</th>
//                       <th className="border-b border-gray-300 p-[8px]">
//                         Weight
//                       </th>
//                       <th className="border-b border-gray-300 p-[8px]">
//                         Price
//                       </th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     {order.LineItems.map((item, index) => (
//                       <tr className="border-b border-gray-300" key={index}>
//                         <td className="border-b border-gray-300 p-2 text-center">
//                           {item.quantity} x
//                         </td>
//                         <td className="border-b border-gray-300 p-2 text-center">
//                           {item.product &&
//                             item.product.ProductImage &&
//                             item.product.ProductImage.length > 0 && (
//                               <img
//                                 className="w-12 h-12"
//                                 src={item.product.ProductImage[0].src}
//                                 alt={item.name}
//                               />
//                             )}
//                         </td>
//                         <td className="border-b border-gray-300 p-2 text-center">
//                           <div>
//                             <div>{item.sku}</div>
//                             <div className="flex justify-center items-center">
//                               {item.sku ? (
//                                 <Barcode
//                                   value={item.sku}
//                                   width={2}
//                                   height={50}
//                                   fontSize={10}
//                                   margin={3}
//                                   className="barcode"
//                                 />
//                               ) : null}
//                             </div>
//                             <div>{item.location}</div>
//                           </div>
//                         </td>
//                         <td className="border-b border-gray-300 p-2 text-center">
//                           {item.name}
//                         </td>
//                         <td className="border-b border-gray-300 p-2 text-center">
//                           {parseFloat((item.grams * item.quantity) / 1000)} kg
//                         </td>
//                         <td className="border-b border-gray-300 p-2 text-center">
//                           {item.price}
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               </div>

//               <div className="flex justify-between mb-1 mt-7">
//                 <div className="sm-full w:w-1/2 p-4">
//                   <p>
//                     <strong>Total Quantities : </strong>{" "}
//                     {order.total_line_items}
//                   </p>
//                   <p>
//                     Order Weight: {parseFloat(order.total_weight / 1000)} Kgs +
//                     Box Weight 90 grams ={" "}
//                     {parseFloat((order.total_weight + 90) / 1000)} Kgs
//                   </p>
//                   <p>
//                     <strong>Order value : </strong> {order.total_price}
//                   </p>
//                 </div>
//                 <div className="sm-full w:w-1/2 p-4">
//                   <p>
//                     <strong>Assigned to : </strong>
//                     {formatName(order.AssignedOrders[0]?.assignTo.full_name)}
//                     {/* {formatName(order.AssignedOrders[0]?.assignTo.last_name)} */}
//                   </p>
//                   <p>
//                     <strong>Printed By : </strong>
//                     {formatName(data.printedBy)}
//                   </p>
//                   <p>
//                     <strong>Assigned by : </strong>
//                     {formatName(order.AssignedOrders[0]?.admin.full_name)}
//                     {/* {formatName(order.AssignedOrders[0]?.admin.last_name)} */}
//                   </p>
//                 </div>
//               </div>
//               <div className="sm-full w:w-1/2 p-4 ">
//                 Picklist generated by Pickpackgo
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default GeneratePickListByProductPrintView;
