import React, { useEffect, useRef, useState } from "react";
import OrderDropdown from "../../../../components/OrdersDropDown";
import Dropdown from "../../../../components/Dropdown";
import Swal from "sweetalert2";
import {
  DELETE_CUSTOM_FILTER,
  GET_ALL_CUSTOM_FILTERS,
} from "../../../../API/Orders";
import { GET_ALL_EMPLOYEES } from "../../../../API/Employee";
import { message } from "antd";
import Icon from "../../../../components/Icon";
import useDarkMode from "use-dark-mode";
import NewCustomFilter from "./NewCustomFilter";

const AllFiltersDropdown = ({
  onSelect,
  filtersReset,
  setFiltersReset,
  customFilters,
  onSelectFilter,
  setCustomFilters,
  // onCloneFilter,
}) => {
  const [editFilterId, setEditFilterId] = useState(null);
  const [isNewFilterModalOpen, setIsNewFilterModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [activeFilterId, setActiveFilterId] = useState(null);
  const componentRef = useRef(null);

  const handleFilterSelect = (filterId) => {
    setActiveFilterId(filterId);
    onSelectFilter(filterId);
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const data = await GET_ALL_EMPLOYEES();
        setEmployees(data);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };
    fetchEmployees();
  }, []);

  useEffect(() => {
    if (filtersReset) {
      setSelectedEmployee(null);
      setFiltersReset(false); // Reset the flag
    }
  }, [filtersReset, setFiltersReset]);

  const handleEmployeeSelect = async (employeeIdOrName) => {
    try {
      if (employeeIdOrName === "") {
        setSelectedEmployee(null);
        onSelect(null);
        return;
      }

      if (!isNaN(employeeIdOrName)) {
        const idAsNumber = parseInt(employeeIdOrName, 10);
        const selected = employees.find((emp) => emp.id === idAsNumber);
        if (selected) {
          setSelectedEmployee(selected);
          onSelect(selected.id);
        } else {
          console.error("Selected employee not found.");
        }
      } else {
        const selected = employees.find(
          (emp) => `${emp.full_name}` === employeeIdOrName
        );
        if (selected) {
          setSelectedEmployee(selected);
          onSelect(selected.id);
        } else {
          console.error("Selected employee not found.");
        }
      }
    } catch (error) {
      console.error("Error selecting employee:", error);
    }
  };

  const dropdownOptions = [
    { label: "Assigned To", value: "" },
    ...employees.map((employee) => ({
      label: `${employee.full_name}`,
      value: employee.id,
    })),
  ];
  useEffect(() => {
    if (visibleModal) {
      fetchCustomFilters();
    }
  }, [visibleModal]);

  const customDropdownStyles = {
    // height: "40px",
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete the custom filter",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      dangerMode: true,
    });

    if (result.isConfirmed) {
      try {
        await DELETE_CUSTOM_FILTER(id);
        message.success("Custom filter deleted successfully");
        setCustomFilters((prevFilters) =>
          prevFilters.filter((filter) => filter.id !== id)
        );
      } catch (error) {
        message.error("Failed to delete custom filter", error);
      }
    }
  };

  const showNewFilterModal = (filterId = null) => {
    setEditFilterId(filterId);
    setVisibleModal(false);
    setIsNewFilterModalOpen(true);
  };

  const handleNewFilterModalOk = () => {
    setVisibleModal(false);
    setEditFilterId(null);
  };

  const handleNewFilterModalCancel = () => {
    setVisibleModal(false);
    setIsNewFilterModalOpen(false);
    setEditFilterId(null);
  };

  const darkMode = useDarkMode(false);

  const fetchCustomFilters = async () => {
    setLoading(true);
    try {
      const response = await GET_ALL_CUSTOM_FILTERS();
      if (Array.isArray(response.data)) {
        setCustomFilters(response.data);
        message.success("Custom filters fetched successfully!");
      } else {
        console.error("Expected an array but got:", typeof response.data);
        message.error("Unexpected response format.");
      }
    } catch (error) {
      console.error("Failed to fetch custom filters:", error);
      message.error("Failed to fetch custom filters. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (id) => {
    showNewFilterModal(id);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setActiveFilterId(null);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <div ref={componentRef}>
        <div className="flex flex-wrap gap-4 sm:flex-col sm:gap-2 w-full">
          <Dropdown
            value={
              selectedEmployee ? selectedEmployee.full_name : "Assigned To"
            }
            setValue={(value) => handleEmployeeSelect(value)}
            options={dropdownOptions.map((option) => option.label)}
            className="custom-dropdown"
            scroll={false}
            dropdownStyles={customDropdownStyles}
          />
          {customFilters.map((filter) => (
            <OrderDropdown
              key={filter.id}
              value={filter.custom_filter_name}
              setValue={() => {}}
              options={[
                {
                  label: "Edit View",
                  icon: <Icon name="edit" size={16} />,
                  onClick: () => handleEdit(filter.id),
                },
                {
                  label: <span className="text-red-500">Delete View</span>,
                  icon: <Icon name="trash" size={16} color="#FF6A55" />,
                  onClick: () => handleDelete(filter.id),
                },
              ]}
              classDropdownHead={`px-4 py-2 rounded-md transition-colors flex justify-between items-center ${
                activeFilterId === filter.id
                  ? darkMode.value
                    ? "border border-gray-800 "
                    : "bg-gray-100"
                  : ""
              }`}
              onLabelClick={() => handleFilterSelect(filter.id)}
            />
          ))}
          <div
            className="flex items-center space-x-2"
            onClick={() => showNewFilterModal()}
          >
            <Icon name="plus-circle" size="24" fill="#6F767E" />
          </div>
        </div>

        <NewCustomFilter
          isNewFilterModalOpen={isNewFilterModalOpen}
          handleNewFilterModalOk={handleNewFilterModalOk}
          handleNewFilterModalCancel={handleNewFilterModalCancel}
          editFilterId={editFilterId}
          fetchCustomFilters={fetchCustomFilters}
        />
      </div>
    </>
  );
};

export default AllFiltersDropdown;
