import React, { useState } from "react";
import styles from "./Sidebar.module.sass";
import { Link, NavLink, useLocation } from "react-router-dom";
import MultiAccessControl from "../../HOC/MultiAccessControl";
import cn from "classnames";
import Icon from "../Icon";
import Theme from "../Theme";
import Dropdown from "./Dropdown";
import Help from "./Help";
import Image from "../Image";
import Logo from "../../assets/images/ppg_log.svg";
import DarkLogo from "../../assets/images/ppg_logo_in_dark.svg";
import Modal from "../Modal";
import InventoryMode from "../../screens/Inventory/InventoryMode";

const navigation = [
  {
    title: "Orders",
    slug: "orders",
    icon: "order",
    moduleCode: ["diMGeD"],
    permissions: ["91VsM3"],
    dropdown: [
      {
        title: "All Orders",
        url: "/orders/",
      },
      // {
      //   title: "Unassigned",
      //   url: "/orders/unassigned",
      // },
    ],
  },
  {
    title: "Staff",
    // slug: "staffs",
    icon: "profile-circle",
    url: "/staffs",
    moduleCode: ["eV79MY"],
    permissions: ["xPGbZk"],
    // dropdown: [
    //   {
    //     title: "Staff list",
    //     url: "/staff",
    //   },
    //   {
    //     title: "Unassigned",
    //     url: "/orders/unassigned",
    //   },
    // ],
  },
  {
    title: "Scan Order",
    icon: "camera",
    url: "/scan-orders",
    moduleCode: ["8FZN9w"],
    permissions: ["a2YwMA"],
  },
  {
    title: "Inventory",
    slug: "inventory",
    icon: "store",
    moduleCode: ["Lzk3eg"],
    permissions: ["kXdmje"],
    dropdown: [
      {
        title: "Update Inventory",
        action: "openUpdateInventoryModal",
      },
      {
        title: "Inventory Logs",
        url: "/inventorylogs",
      },
    ],
  },
  {
    title: "Settings",
    slug: "Settings",
    icon: "setting",
    url: "/settings",
    moduleCode: ["gsZHRE", "FvrVqN", "BjQPiy"],
    permissions: ["nnlrNJ", "nJLdKh", "OZnKi4"],
  },

  // {
  //   title: "Scan Order",
  //   icon: "camera",
  //   url: "/scanorder",
  // },

  // {
  //   title: "Home",
  //   icon: "home",
  //   url: "/dashboard",
  // },
  // {
  //   title: "Products",
  //   slug: "products",
  //   icon: "diamond",
  //   add: true,
  //   dropdown: [
  //     {
  //       title: "Dashboard",
  //       url: "/products/dashboard",
  //     },
  //     {
  //       title: "Drafts",
  //       url: "/products/drafts",
  //       counter: "2",
  //       colorCounter: "#FFBC99",
  //     },
  //     {
  //       title: "Released",
  //       url: "/products/released",
  //     },
  //     {
  //       title: "Comments",
  //       url: "/products/comments",
  //     },
  //     {
  //       title: "Scheduled",
  //       url: "/products/scheduled",
  //       counter: "8",
  //       colorCounter: "#B5E4CA",
  //     },
  //   ],
  // },
  // {
  //   title: "Customers",
  //   slug: "customers",
  //   icon: "profile-circle",
  //   dropdown: [
  //     {
  //       title: "Overview",
  //       url: "/customers/overview",
  //     },
  //     {
  //       title: "Customer list",
  //       url: "/customers/customer-list",
  //     },
  //   ],
  // },
  // {
  //   title: "Shop",
  //   icon: "store",
  //   url: "/shop",
  // },
  // {
  //   title: "Income",
  //   slug: "income",
  //   icon: "pie-chart",
  //   dropdown: [
  //     {
  //       title: "Earning",
  //       url: "/income/earning",
  //     },
  //     {
  //       title: "Refunds",
  //       url: "/income/refunds",
  //     },
  //     {
  //       title: "Payouts",
  //       url: "/income/payouts",
  //     },
  //     {
  //       title: "Statements",
  //       url: "/income/statements",
  //     },
  //   ],
  // },
  // {
  //   title: "Promote",
  //   icon: "promotion",
  //   url: "/promote",
  // },
];

const Sidebar = ({ className, onClose }) => {
  
  const [visibleHelp, setVisibleHelp] = useState(false);
  const [visible, setVisible] = useState(false);
 
  const [isUpdateInventoryModalVisible, setIsUpdateInventoryModalVisible] =
    useState(false);
  const { pathname } = useLocation();
  const handleAction = (action) => {
    if (action === "openUpdateInventoryModal") {
      setIsUpdateInventoryModalVisible(true);
    }
    // Add other action handlers here if needed
  };
  return (
    <>
      <div
        className={cn(styles.sidebar, className, {
          [styles.active]: visible,
        })}
      >
        <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="24" />
        </button>
        <Link className={styles.logo} to="/" onClick={onClose}>
          <Image
            className={styles.pic}
            src={Logo}
            // srcDark="/images/logo-light.png"
            srcDark={DarkLogo}
            alt="Core"
          />
        </Link>
        <div className={styles.menu}>
          {navigation.map((x, index) =>
            x.url ? (
              <MultiAccessControl
                moduleCodes={x.moduleCode}
                requiredPrivileges={x.permissions}
              >
                {" "}
                <NavLink
                  className={cn(styles.item, {
                    [styles.active]: pathname === x.url,
                  })}
                  to={x.url}
                  key={index}
                  onClick={onClose}
                >
                  <Icon name={x.icon} size="24" />
                  {x.title}
                </NavLink>
              </MultiAccessControl>
            ) : (
              <MultiAccessControl
                moduleCodes={x.moduleCode}
                requiredPrivileges={x.permissions}
              >
                <Dropdown
                  className={styles.dropdown}
                  visibleSidebar={visible}
                  setValue={setVisible}
                  key={index}
                  item={x}
                  onClose={onClose}
                  onAction={handleAction}
                />
              </MultiAccessControl>
            )
          )}
        </div>
        <button className={styles.toggle} onClick={() => setVisible(!visible)}>
          <Icon name="arrow-right" size="24" />
          <Icon name="close" size="24" />
        </button>
        <div className={styles.foot}>
          {/* <button className={styles.link} onClick={() => setVisibleHelp(true)}> */}
          {/* <button className={styles.link}>
            <Icon name="help" size="24" />
            Help & getting started
            <div className={styles.counter}>8</div>
          </button> */}
          <Theme className={styles.theme} visibleSidebar={visible} />
        </div>
      </div>
      <Help
        visible={visibleHelp}
        setVisible={setVisibleHelp}
        onClose={onClose}
      />
      <div
        className={cn(styles.overlay, { [styles.active]: visible })}
        onClick={() => setVisible(false)}
      ></div>
      <Modal
        visible={isUpdateInventoryModalVisible}
        onClose={() => setIsUpdateInventoryModalVisible(false)}
      >
        {/* <InventoryMode /> */}
        <InventoryMode
          onClose={() => setIsUpdateInventoryModalVisible(false)}
        />
      </Modal>
    </>
  );
};

export default Sidebar;
