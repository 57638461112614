import custom_axios from "../axios";
export const get_store_settings = async (id, formData) => {
  try {
    const response = await custom_axios.get(`/store-settings/`);
    return response.data;
  } catch (error) {
    console.error("Failed to find store settings", error);
    return error;
  }
};

export const update_barcode_settings = async (settings) => {
  try {
    const response = await custom_axios.post(
      `/store-settings/barcode_settings`,
      { barcode_visibility: settings }
    );
    return response;
  } catch (error) {
    console.error("Failed to find store settings", error);
    throw error;
  }
};
