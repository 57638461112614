import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Modal from "../../../../../../../components/Modal";
import Details from "../../index";

const Row = ({ item }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  return (
    <>
      <div className={styles.row} onClick={() => setVisibleModal(true)}>
        <div className={styles.col}>
          <div className={styles.item}>
            {/* <div className={styles.preview}>
              {item.product.ProductImage.map((image, index) => (
                <img
                  srcSet={`${image.src} 2x`}
                  key={index}
                  src={image.src}
                  alt="Product"
                />
              ))}
            </div> */}
            <div className={styles.preview}>
              {item.product && item.product.ProductImage ? (
                item.product.ProductImage.map((image, index) => (
                  <img
                    srcSet={`${image.src} 2x`}
                    key={index}
                    src={image.src}
                    alt="Product"
                  />
                ))
              ) : (
                <div className="mt-[21%]">No Image</div>
              )}
            </div>
            <div className={styles.details}>
              <div className={styles.product}>{item.name}</div>
              <div className={styles.category}>
                {item.product && item.product.product_type
                  ? item.product.product_type
                  : ""}
              </div>
            </div>
          </div>
        </div>
        {/* <div className={styles.col}>
          <div className={cn("status-yellow", styles.status)}>
            {item?.fulfillment_status
              ? item.fulfillment_status.replace(/_/g, " ")
              : "N/A"}
          </div>
        </div> */}
        <div className={styles.col}>
          {item.quantity < 10 ? `0${item.quantity}` : item.quantity}
        </div>
        <div className={styles.col}>{item.price}</div>
        <div className={styles.col}></div>
      </div>
      {/* <Details item={item} /> */}
      {/* <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
      >
      
      </Modal> */}
    </>
  );
};

export default Row;
