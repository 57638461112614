import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import AccessControl from "../../../HOC/AccessControl";
import styles from "./Released.module.sass";
import Card from "../../../components/Card";
import Form from "../../../components/Form";
import Icon from "../../../components/Icon";
import OrdersTable from "./OrdersTable";
import Filters from "./Filters/Filters";
import { Divider, message, Tooltip } from "antd";
import OrdersAssignedStaffDropdown from "./Filters/OrdersAssignedStaffDropdown";
import GeneratePickList from "./Filters/GeneratePickList";
import AssignToPickerOrPacker from "./Filters/AssignToPickerOrPacker";
import DateFilter from "./Filters/DateFilter";
import Export from "./Filters/Export";
import Filter from "./Filters/IndexFilter";
import useDarkMode from "use-dark-mode";
import {
  GET_FETCH_TAG_SUGGESTION,
  GET_ORDER_FILTER_OPTIONS_BY_TYPE,
  GET_ALL_CUSTOM_FILTERS,
  order_details,
} from "../../../API/Orders";
import { SEND_SELECTED_EMPLOYEE_ID } from "../../../API/Employee";
import CustomizingOrderFields from "./Filters/CustomizingOrderFields";
import AllFiltersDropdown from "./Filters/AllFilersDropdown";
import Swal from "sweetalert2";
import CustomToast from "../../../components/CustomToast";
import { get_store_sync_status } from "../../../API/Store";
import GeneratePickListPopUp from "./Modals/GeneratePicklist/GeneratePickList";
import Loader from "../../../components/Loader";
// import GeneratePickListByOrderPrintView from "./Filters/GeneratePickListByOrderPrintView";
// import GeneratePickListByProductPrintView from "./Filters/GeneratePickListByProductPrintView";
// import Tooltip from "../../../components/Tooltip";
// import CustomFilterList from "./Filters/CustomeFilter";

const AllOrders = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [assignvisibleModal, setAssignVisibleModal] = useState(false);
  const [picklistvisibleModal, setPicklistVisibleModal] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);
  const [search, setSearch] = useState("");
  const [searchVisible, setSearchVisible] = useState(false);
  const [iconVisible, setIconVisible] = useState(true);
  const [newButtonName, setNewButtonName] = useState("");
  const [buttons, setButtons] = useState([]);
  const [syncStatus, setSyncStatus] = useState();
  const darkMode = useDarkMode(false);

  const findSyncStatus = async () => {
    try {
      setLoading(true);
      const store_sync_status = await get_store_sync_status();

      const order_sync =
        store_sync_status.data.order_sync === "COMPLETED" ? true : false;
      setSyncStatus(order_sync);
    } catch (err) {
      setSyncStatus(true);
    } finally {
      setLoading(false);
    }
  };

  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState({
    risk: [],
    tags: [],
    notTaggedWith: [],
    delivery: "",
    financial: "",
    app: [],
    returnStatus: [],
    channel: [],
    fulfillment: "",
    shopifyOrderStatus: [],
  });
  const [fulfilmentOptions, setFulfilmentOptions] = useState([]);
  const [deliveryStatusOptions, setDeliveryStatusOptions] = useState([]);
  const [deliveryMethodOptions, setDeliveryMethodOptions] = useState([]);
  const [financialStatusOptions, setFinancialStatusOptions] = useState([]);
  const [optionsApp, setOptionsApp] = useState([]);
  const [optionsRisk, setOptionsRisk] = useState([]);
  const [optionShopifyStatus, setShopifyStatus] = useState([]);
  const [optionsReturn, setOptionsReturn] = useState([]);
  const [valueFilterByDate, setValueFilterByDate] = useState(0);
  const [valueFilterByToDate, setValueFilterByToDate] = useState("");
  const [valueFilterByFromDate, setValueFilterByFromDate] = useState("");
  const [checkedItems, setCheckedItems] = useState({});
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  // const [itemsPerPage, setItemsPerPage] = useState(10);
  const [itemsPerPage, setItemsPerPage] = useState(() => {
    const savedPageSize = localStorage.getItem("itemsPerPage");
    return savedPageSize ? parseInt(savedPageSize, 10) : 10;
  });
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [filtersReset, setFiltersReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [taggedWithSuggestions, setTaggedWithSuggestions] = useState([]);
  const [notTaggedWithSuggestions, setNotTaggedWithSuggestions] = useState([]);
  const [customFieldTrigger, setCustomFieldTrigger] = useState(false);
  const [customFilters, setCustomFilters] = useState([]);
  const [customFilter, setCustomFilter] = useState(null);
  const [showAllFiltersDropdown, setShowAllFiltersDropdown] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  // Generate Picklist
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [selectOrderVisible, setSelectOrderVisible] = useState(false);
  const [selectedPickListType, setSelectedPickListType] = useState();
  const [orderDetails, setOrdersDetails] = useState();
  const dropdownRef = useRef(null);
  //assign to
  const [assignModalVisible, setAssignModalVisible] = useState(false);
  //CustomToast
  const showToast = CustomToast();

  const toggleAllFiltersDropdown = () => {
    setShowFilters(false);
    setShowAllFiltersDropdown((prev) => !prev);
  };
  useEffect(() => {
    fetchCustomFilters();
  }, []);
  const handleItemsPerPageChange = (newPageSize) => {
    setItemsPerPage(newPageSize);
    localStorage.setItem("itemsPerPage", newPageSize.toString());
  };
  const fetchCustomFilters = async () => {
    try {
      const response = await GET_ALL_CUSTOM_FILTERS();
      if (response.data && Array.isArray(response.data)) {
        setCustomFilters(response.data);
      } else {
        console.error("Unexpected data format:", response);
      }
    } catch (error) {
      console.error("Error fetching custom filters:", error);
    }
  };

  const handleSelectFilter = (filterKey) => {
    setCustomFilter(filterKey);
  };

  const handleCustomFieldChange = () => {
    setCustomFieldTrigger((prev) => !prev);
  };
  // const handleSelectFilter = (filterKey) => {
  //   setCustomFilter(filterKey);
  // };

  useEffect(() => {
    findSyncStatus();
    fetchFilterOptions();
  }, []);

  const fetchFilterOptions = async () => {
    try {
      const data = await GET_ORDER_FILTER_OPTIONS_BY_TYPE();

      const mapOptions = (options) =>
        Object.entries(options || {}).map(([key, value]) => ({
          label: key,
          value,
        }));

      const fulfilmentOptions = mapOptions(data?.fulfillmentStatus);
      const deliveryStatusOptions = mapOptions(data?.deliveryStatus);
      const deliveryMethod = mapOptions(data?.deliveryMethod);
      const financialStatusOptions = mapOptions(data?.financialStatus);
      const optionsApp = mapOptions(data?.app);
      const optionsRisk = mapOptions(data?.risk);
      const optionsReturn = mapOptions(data?.returnStatus);
      const shopifyOrderStatus = mapOptions(data?.shopifyOrderStatus);

      setFulfilmentOptions(fulfilmentOptions);
      setDeliveryStatusOptions(deliveryStatusOptions);
      setDeliveryMethodOptions(deliveryMethod);
      setFinancialStatusOptions(financialStatusOptions);
      setOptionsApp(optionsApp);
      setOptionsRisk(optionsRisk);
      setOptionsReturn(optionsReturn);
      setShopifyStatus(shopifyOrderStatus);
    } catch (error) {
      CustomToast.fire({
        icon: "error",
        text: `Failed to fetch filter options:", error`,
      });
      // message.error("Failed to fetch filter options:", error);
    }
  };

  const handleCheckboxChange = (event, newCheckedItems = null, id = null) => {
    if (newCheckedItems) {
      // console.log("Updating all items:", newCheckedItems);
      setCheckedItems(newCheckedItems);
    } else if (id !== null) {
      // console.log("Toggling item:", id);
      setCheckedItems((prev) => {
        const updated = { ...prev, [id]: !prev[id] };
        // console.log("Updated checked items:", updated);
        return updated;
      });
    }
  };

  useEffect(() => {
    // Reset checked items when page or itemsPerPage changes
    setCheckedItems({});
  }, [page, itemsPerPage]);

  const handleDateFilterChange = (
    valueByDate,
    valueByFromDate,
    valueByToDate  
  ) => {
    setValueFilterByDate(valueByDate);
    setValueFilterByToDate(valueByToDate);
    setValueFilterByFromDate(valueByFromDate);
  };

  const updateFilter = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value,
    }));
    setCustomFieldTrigger((prev) => !prev);
  };
  const handleEmployeeSelect = (employee) => {
    setSelectedEmployee(employee);
  };
  const handleTaggedWithSearch = async (value) => {
    setLoading(true);
    try {
      const response = await GET_FETCH_TAG_SUGGESTION(value);
      const suggestions = response.data.map((item) => item.tag_name.trim());
      setTaggedWithSuggestions(suggestions);
    } catch (error) {
      CustomToast.fire({
        icon: "error",
        text: `Failed to fetch suggestions for taggedWith:", error`,
      });
      // message.error("Failed to fetch suggestions for taggedWith:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNotTaggedWithSearch = async (value) => {
    setLoading(true);
    try {
      const response = await GET_FETCH_TAG_SUGGESTION(value);
      const suggestions = response.data.map((item) => item.tag_name.trim());
      setNotTaggedWithSuggestions(suggestions);
    } catch (error) {
      CustomToast.fire({
        icon: "error",
        text: `Failed to fetch suggestions for notTaggedWith:", ${error}`,
      });
      // message.error("Failed to fetch suggestions for notTaggedWith:", error);
    } finally {
      setLoading(false);
    }
  };
  const resetFilters = () => {
    setFilters({
      risk: [],
      tags: [],
      notTaggedWith: [],
      delivery: "",
      deliveryMethod: "",
      financial: "",
      app: [],
      returnStatus: [],
      channel: [],
      shopifyOrderStatus: [],
      fulfillment: "",
    });
    setValueFilterByDate(0);
    setValueFilterByToDate("");
    setValueFilterByFromDate("");
    setCustomFilter("");
    setSelectedEmployee(null);
    setCheckedItems({});
    setFiltersReset(true);
    setSelectedEmployee("");
    setSearch("");
  };
  const filterConfigs = [
    {
      title: "Fulfillment Status",
      type: "radio",
      values: fulfilmentOptions,
      onChange: (value) => updateFilter("fulfillment", value),
      isTagFilter: false,
    },
    {
      title: "Tagged With",
      type: "select",
      onChange: (value) => updateFilter("tags", value),
      onSearch: handleTaggedWithSearch,
      suggestions: taggedWithSuggestions,
      isTagFilter: true,
    },
    {
      title: "Not Tagged With",
      type: "select",
      onChange: (value) => updateFilter("notTaggedWith", value),
      onSearch: handleNotTaggedWithSearch,
      suggestions: notTaggedWithSuggestions,
      isTagFilter: true,
    },
    {
      title: "Delivery Status",
      type: "radio",
      values: deliveryStatusOptions,
      onChange: (value) => updateFilter("delivery", value),
      isTagFilter: false,
    },
    {
      title: "Delivery Method",
      type: "radio",
      values: deliveryMethodOptions,
      onChange: (value) => updateFilter("deliveryMethod", value),
      isTagFilter: false,
    },
    {
      title: "Financial Status",
      type: "radio",
      values: financialStatusOptions,
      onChange: (value) => updateFilter("financial", value),
      isTagFilter: false,
    },
    {
      title: "App",
      type: "select",
      values: optionsApp,
      onChange: (value) => updateFilter("app", value),
      isTagFilter: false,
    },
    {
      title: "Return",
      type: "select",
      values: optionsReturn,
      onChange: (value) => updateFilter("returnStatus", value),
      isTagFilter: false,
    },
    {
      title: "Channel",
      type: "select",
      values: optionsApp,
      onChange: (value) => updateFilter("channel", value),
      isTagFilter: false,
    },
    {
      title: "Risk Level",
      type: "select",
      values: optionsRisk,
      onChange: (value) => updateFilter("risk", value),
      isTagFilter: false,
    },
    {
      title: "Order Status",
      type: "select",
      values: optionShopifyStatus,
      onChange: (value) => updateFilter("shopifyOrderStatus", value),
      isTagFilter: false,
    },
  ];

  const generatePicklist = [
    {
      id: "order",
      title: "By Order",
      module: "diMGeD",
      permission: "wwkpjt",
    },
    {
      id: "product",
      title: "By Product",
      module: "diMGeD",
      permission: "To6Daz",
    },
  ];

  // const toggleFilters = () => {
  //   setShowAllFiltersDropdown(false);
  //   setShowFilters((prevState) => !prevState);
  // };

  // const handleSearchIconClick = () => {
  //   setSearchVisible(true);
  //   setIconVisible(false);
  // };
  const toggleFilters = () => {
    setShowAllFiltersDropdown(false);
    setShowFilters((prevState) => !prevState);
    setActiveButton("filter");
  };

  const handleSearchIconClick = () => {
    setSearchVisible(true);
    setIconVisible(false);
    setActiveButton("search");
  };

  const handleCancelButtonClick = () => {
    setSearchVisible(false);
    setIconVisible(true);
    setSearch("");
  };

  // const handleFilterModel = () => {
  //   setVisibleModal(true);
  // };

  const handleButtonClick = async () => {
    const ids = Object.keys(checkedItems)
      .filter((id) => checkedItems[id])
      .map((id) => Number(id));
    try {
      if (ids.length > 0) {
        await SEND_SELECTED_EMPLOYEE_ID(ids);
        showToast("Successfully unassigned orders.", "success");
        updateFilter();
      } else {
        showToast("No orders selected.", "error");
      }
    } catch (error) {
      showToast("Failed to unassign orders.", "error");
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const fetchOrderDetails = async () => {
    try {
      if (checkedItems) {
        const checkedIds = Object.keys(checkedItems).filter(
          (id) => checkedItems[id]
        );
        const response = await order_details(checkedIds);
        const result = response.data.data.map((item) => item.name).join(", ");
        setOrdersDetails(result);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleOptionClick = (option) => {
    const selectedItems = Object.keys(checkedItems).filter(
      (id) => checkedItems[id]
    );
    if (selectedItems.length === 0) {
      Swal.fire({
        title: "Warning",
        text: "Please select one or multiple orders.",
        icon: "warning",
        confirmButtonColor: "#3085D6",
        confirmButtonText: "Okay",
      }).then(() => {
        setIsOpen(false);
      });
      return;
    }
    setSelectOrderVisible(true);
    fetchOrderDetails();
    setIsOpen(false);
    setSelectedPickListType(option.id);
  };
  // assign to
  const handleAssignToClick = () => {
    const selectedItems = Object.keys(checkedItems).filter(
      (id) => checkedItems[id]
    );
    if (selectedItems.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "No Orders Selected",
        text: "Please select one or multiple orders.",
      });
    } else {
      setAssignModalVisible(true);
    }
  };
  return (
    <>
      {loading && <Loader className={styles.loader} />}

      <Card className={styles.card}>
        {!syncStatus ? (
          <div
            className="mb-[2%] w-[100%]"
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p>
              We're syncing your store data. Please refresh the page in a few
              moments.
            </p>
            <div style={{ marginTop: "10px" }}>
              <button className={cn("button")} onClick={() => findSyncStatus()}>
                Refresh
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className="flex justify-between items-center  mb-12">
              <div className={cn("title-purple", styles.subtitle)}>
                View Order
              </div>
              <div className="flex items-center space-x-2">
                {/* {(showFilters ||
                    selectedEmployee ||
                    showAllFiltersDropdown) && ( */}
                <button
                  className={cn(
                    "button-clear-stroke button-clear-stroke-red button-small",
                    styles.button
                  )}
                  onClick={resetFilters}
                >
                  <Icon name="clearAll" size="24" /> Clear All Filters
                </button>
                {/* )} */}
                <button
                  className={cn("button-stroke button-small")}
                  onClick={handleAssignToClick}
                >
                  Assign To
                </button>
                {selectedEmployee && (
                  <button
                    className={cn("button-stroke button-small")}
                    onClick={handleButtonClick}
                  >
                    Unassign
                  </button>
                )}
                <div className="relative z-50" ref={dropdownRef}>
                  <button
                    onClick={toggleDropdown}
                    className={cn(
                      styles.dropdownButton,
                      "button-stroke-order button-small"
                    )}
                  >
                    Generate Picklist
                    <span
                      className={`${styles.dropdownIcon} ${
                        isOpen ? styles.open : ""
                      }`}
                    ></span>
                  </button>

                  {isOpen && (
                    <div
                      className={cn(
                        `absolute right-0 w-full mt-2 rounded-md shadow-lg  ring-1 ring-black ring-opacity-5 z-50 ${
                          darkMode ? "bg-[white]" : ""
                        }`,
                        styles.dropdownoptions
                      )}
                    >
                      {generatePicklist.map((option) => (
                        <AccessControl
                          key={option.id}
                          moduleCode={option.module}
                          requiredPrivilege={option.permission}
                        >
                          <button
                            onClick={() => handleOptionClick(option)}
                            className={cn(
                              `w-full text-left px-4 py-2 text-sm ${
                                darkMode
                                  ? "text-[#6F767E] hover:text-[white] "
                                  : "text-[#6F767E] hover:text-[black]"
                              }                              `,
                              styles.option
                            )}
                          >
                            {option.title}
                          </button>
                        </AccessControl>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <AssignToPickerOrPacker
              assignvisibleModal={assignModalVisible}
              setAssignVisibleModal={setAssignModalVisible}
              checkedItems={checkedItems}
              updateFilter={updateFilter}
              handleCheckboxChange={handleCheckboxChange}
            />
            <GeneratePickListPopUp
              visible={selectOrderVisible}
              setVisible={setSelectOrderVisible}
              checkedItems={checkedItems}
              updateFilter={updateFilter}
              handleCheckboxChange={handleCheckboxChange}
              selectedPickListType={selectedPickListType}
              orderDetails={orderDetails}
            />
            <div className="flex gap-4 sm:flex-col sm:gap-2 md:flex-col md:gap-2  w-full">
              {searchVisible && (
                <div className="w-[100%]" style={{ display: "flex" }}>
                  <Form
                    className={cn(
                      styles.form,
                      "w-[100%]",
                      " sm:w-full",
                      "md:w-[100%]",
                      "lg:w-[100%]",
                      "xl:w-[100%]"
                    )}
                    value={search}
                    setValue={setSearch}
                    // onSubmit={handleSubmit}
                    placeholder="Search By Order Number"
                    type="text"
                    name="search"
                    icon="search"
                  />

                  <button
                    style={{
                      whiteSpace: "nowrap",
                      padding: "10px",
                      fontSize: "13px",
                    }}
                    className={cn(
                      "button-stroke button-small",
                      styles.button,
                      "ml-[2%]",
                      "mr-[1%]"
                    )}
                    onClick={handleCancelButtonClick}
                  >
                    Cancel
                  </button>
                </div>
              )}

              {iconVisible && (
                <div
                  className={
                    "flex items-center gap-4 sm:flex-col sm:gap-2 md:flex-col md:gap-2 w-full"
                  }
                >
                  <div>
                    <AllFiltersDropdown
                      customFilters={customFilters}
                      onSelectFilter={handleSelectFilter}
                      setCustomFilters={setCustomFilters}
                      onSelect={handleEmployeeSelect}
                      checkedItems={checkedItems}
                      updateFilter={updateFilter}
                      handleCheckboxChange={handleCheckboxChange}
                      filtersReset={filtersReset}
                      setFiltersReset={setFiltersReset}
                    />
                  </div>
                </div>
              )}
              <div className="flex gap-[25px]">
                {iconVisible && (
                  <div
                    className={cn(
                      "flex border-2 border-[#F4F4F4] p-[5px_9px] h-[38px] rounded-[8px]",
                      styles.filtericon
                    )}
                  >
                    <Tooltip title="Search">
                      <button
                        className={cn(styles.link, {
                          [styles.active]: activeButton === "search",
                        })}
                        onClick={handleSearchIconClick}
                      >
                        <Icon name="search" size="24" />
                      </button>
                    </Tooltip>
                    <Tooltip title="Filters">
                      <button
                        className={cn(styles.link, {
                          [styles.active]: activeButton === "filter",
                        })}
                        onClick={toggleFilters}
                      >
                        <Icon name="customizingorder" size="24" />
                      </button>
                    </Tooltip>
                  </div>
                )}
                <div
                  className={cn(
                    "flex border-2 border-[#F4F4F4] p-[5px_9px] h-[38px] rounded-[8px]",
                    styles.filtericon
                  )}
                >
                  <Export
                    activeButton={activeButton}
                    setActiveButton={setActiveButton}
                    toggleFilters={toggleFilters}
                    checkedItems={checkedItems}
                    handleCheckboxChange={handleCheckboxChange}
                    page={page}
                    setPage={setPage}
                    totalItems={totalItems}
                    setTotalItems={setTotalItems}
                    search={search}
                    {...filters}
                    valueFilterByDate={valueFilterByDate}
                    valueFilterByToDate={valueFilterByToDate}
                    valueFilterByFromDate={valueFilterByFromDate}
                    itemsPerPage={itemsPerPage}
                    selectedEmployee={selectedEmployee}
                    customFilters={customFilter}
                  />
                </div>
                <div
                  className={cn(
                    "flex border-2 border-[#F4F4F4] p-[5px_9px] h-[38px] rounded-[8px]",
                    styles.filtericon
                  )}
                >
                  <CustomizingOrderFields
                    onCustomFieldChange={handleCustomFieldChange}
                    activeButton={activeButton}
                    setActiveButton={setActiveButton}
                  />
                </div>
              </div>
            </div>

            {showFilters && (
              <>
                <Divider />
                <div
                  className={"w-[100%]"}
                  style={{ display: "flex", gap: "3px", flexWrap: "wrap" }}
                >
                  <DateFilter
                    valueFilterByDate={valueFilterByDate}
                    setValueFilterByDate={setValueFilterByDate}
                    valueFilterByToDate={valueFilterByToDate}
                    valueFilterByFromDate={valueFilterByFromDate}
                    onDateFilterChange={handleDateFilterChange}
                  />
                  {filterConfigs.map((config, index) => (
                    <Filter
                      key={index}
                      title={config.title}
                      type={config.type}
                      values={config.values}
                      onChange={config.onChange}
                      onSearch={config.onSearch}
                      suggestions={config.suggestions}
                      isTagFilter={config.isTagFilter}
                    />
                  ))}
                </div>
              </>
            )}

            {showAllFiltersDropdown && activeIndex === 0 && (
              <div className={cn("w-[100%] flex gap-[5px] flex-wrap")}>
                <AllFiltersDropdown
                  customFilters={customFilters}
                  onSelectFilter={handleSelectFilter}
                  setCustomFilters={setCustomFilters}
                />
              </div>
            )}
            <div className={cn(styles.wrapper, "mt-[5%]")}>
              <OrdersTable
                search={search}
                {...filters}
                valueFilterByDate={valueFilterByDate}
                valueFilterByToDate={valueFilterByToDate}
                valueFilterByFromDate={valueFilterByFromDate}
                checkedItems={checkedItems}
                handleCheckboxChange={handleCheckboxChange}
                page={page}
                setPage={setPage}
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={handleItemsPerPageChange}
                setTotalItems={setTotalItems}
                selectedEmployee={selectedEmployee}
                customFilters={customFilter}
                customFieldTrigger={customFieldTrigger}
              />
            </div>
          </>
        )}
      </Card>
    </>
  );
};

export default AllOrders;
