import React, { useState } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import styless from "./overviesStyles.module.sass";
import Icon from "../../../../../../components/Icon";
import ModalPreview from "../../../../../../components/ModalPreview";
import Row from "./Row/Row";
import Parameter from "../Parameter/index";
import { Link } from "react-router-dom";
import moment from "moment";
import { Card, Divider } from "antd";

const gallery = [
  "/images/content/photo-1.jpg",
  "/images/content/photo-2.jpg",
  "/images/content/photo-1.jpg",
  "/images/content/photo-2.jpg",
];

const Overview = ({ orderDetails, orderNumber }) => {
  const [visibleModalPreview, setVisibleModalPreview] = useState(false);
  const ShippingAddress = orderDetails?.ShippingAddress ?? [];
  const bill = [
    {
      title: "Subtotal",
      content: `₹ ${
        orderDetails && orderDetails.OrderDetails && orderDetails.OrderDetails
          ? orderDetails.OrderDetails.total_line_items_price
          : "N/A"
      }`,
    },
    {
      title: "Shipping",
      content: `₹ ${orderDetails?.total_shipping_price}`,
    },
    {
      title: "Discount",
      content: `₹ ${orderDetails?.total_discounts || "N/A"}`,
      ...(orderDetails?.OrderDiscountDetails?.[0]?.code && {
        discount: orderDetails.OrderDiscountDetails[0].code,
      }),
    },
    // {
    //   title: "Discount",
    //   content: `₹ ${orderDetails?.total_discounts}`,
    //   discount: `${
    //     orderDetails?.OrderDiscountDetails[0]?.code
    //       ? orderDetails?.OrderDiscountDetails[0]?.code
    //       : "N/A"
    //   }`,
    // },
    {
      title: "Tax",
      tooltip: "Tax",
      content: `₹ ${
        orderDetails && orderDetails.OrderDetails && orderDetails.OrderDetails
          ? orderDetails.OrderDetails.total_tax
          : "N/A"
      }`,
    },
    {
      title: "Total",
      tooltip: "Total",
      content: `₹ ${orderDetails?.total_price}`,
    },
  ];
  const tagNames = orderDetails?.Tags.map((tag) => tag.tag_name);
  const tagClassNames = [
    "status-yellow",
    "status-green-dark",
    "status-purple-dark",
    "status-light_orange-dark",
    "status-light-dark",
  ];
  const formatTagName = (name) => {
    return name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const formatDateTime = (date) => {
    const today = moment().startOf("day");
    const processedDate = moment(date);

    if (processedDate.isSame(today, "day")) {
      return `Today at ${processedDate.format("h:mm A")}`;
    } else if (processedDate.isSame(today.subtract(1, "day"), "day")) {
      return `Yesterday at ${processedDate.format("h:mm A")}`;
    } else {
      return processedDate.format("ddd MMM DD YYYY, h:mm A");
    }
  };

  if (!orderDetails) {
    return null;
  }
  return (
    <>
      <div className={styles.overview}>
        <div className="flex justify-between">
          <div className="flex gap-2">
            <div className={cn(styles.h4, styles.title)}>
              {/* {orderDetails?.fulfillment_status ?? "Unfulfilled"} */}
              {orderDetails.name}
            </div>

            <div className="flex gap-2">
              <div
                className={cn(
                  orderDetails?.financial_status
                    ? "status-green-dark ml-[7px]"
                    : "",
                  styles.status,
                  "h-[33px]"
                )}
              >
                {orderDetails?.financial_status
                  ? orderDetails.financial_status.charAt(0).toUpperCase() +
                    orderDetails.financial_status.slice(1).replace(/_/g, " ")
                  : ""}
              </div>

              <div
                className={cn(
                  orderDetails?.fulfillment_status ? "status-yellow" : "",
                  styles.status,
                  "h-[33px]"
                )}
              >
                {orderDetails?.fulfillment_status
                  ? orderDetails.fulfillment_status.charAt(0).toUpperCase() +
                    orderDetails.fulfillment_status.slice(1).replace(/_/g, " ")
                  : null}
              </div>
            </div>
          </div>
          <div>
            <Link
              className={cn(
                "button-stroke button-small hover:text-[black]",
                styles.button
              )}
              to="/orders/"
            >
              <Icon name="arrow-left" size="24" />
              <span>Back</span>
            </Link>
          </div>
        </div>
        <div className={styles.info}>
          {orderDetails?.processed_at
            ? formatDateTime(orderDetails.processed_at)
            : ""}
        </div>

        <div className={cn(styles.roww, "mt-[25px]")}>
          {orderDetails.LineItems && orderDetails.LineItems.length > 0 ? (
            <div className={styles.coll}>
              <div className={styles.wrapper}>
                <div className={styless.table}>
                  <div className={styless.row}>
                    <div className={styless.col}>Product</div>
                    <div className={styless.col}>Quantity</div>
                    <div className={styless.col}>Price</div>
                  </div>
                  {orderDetails.LineItems.map((item, index) => (
                    <Row item={item} key={index} />
                  ))}
                </div>
              </div>
            </div>
          ) : null}

          <div className={cn(styles.roww, "mt-[25px]")}>
            {orderDetails.refundedLineItems &&
            orderDetails.refundedLineItems?.length > 0 ? (
              <div className={styles.coll}>
                <div className={styles.wrapper}>
                  <div className={styless.table}>
                    <div className={styless.row}>
                      <div className={styless.col}>Refunds</div>
                      <div className={styless.col}>Quantity</div>
                      <div className={styless.col}>Price</div>
                    </div>
                    {orderDetails.refundedLineItems.map((item, index) => (
                      <Row item={item} key={index} />
                    ))}
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div className={cn(styles.roww, "mt-[60px]")}>
            <div className={styles.coll}>
              <div className={cn("title-purple", styles.subtitle)}>
                Billing Details
              </div>
              <div className={styles.parameters}>
                {bill.map((refund, refundIndex) => (
                  <Parameter
                    item={orderDetails}
                    key={refundIndex}
                    refund={refund}
                  />
                ))}
              </div>
            </div>
          </div>
          {/* customer , tags  */}
          <div className={cn("mt-[60px]")}>
            <div>
              <div className={cn("title-blue", styles.subtitle)}>Customer</div>

              <div className={cn(styles.item)}>
                <div className={styles.details}>
                  <div className={cn(styles.time, "mt-[6px]")}>
                    {`${orderDetails?.Customer?.first_name || " Name"} ${
                      orderDetails?.Customer?.last_name || " Not Available"
                    }`}
                  </div>
                </div>
              </div>
              <div>
                <div className="mb-[10px] mt-[15px]">
                  <a
                    className={styles.line}
                    href={`mailto:${orderDetails?.Customer?.email || "N/A"}`}
                    rel="noopener noreferrer"
                  >
                    <Icon name="mail" size="24" />
                    {orderDetails.Customer?.email || "N/A"}
                  </a>
                </div>
                <div>
                  <a
                    className={styles.line}
                    href={`tel:${orderDetails.Customer?.phone || "N/A"}`}
                    rel="noopener noreferrer"
                  >
                    <Icon name="phone" size="24" />
                    {orderDetails.Customer?.phone || "N/A"}
                  </a>
                </div>
              </div>

              <Divider />
              <div className={cn(styles.man, "mt-[15px] mb-[15px]")}>
                Shipping Address
              </div>
              {ShippingAddress.length > 0 ? (
                ShippingAddress.map((address, index) => (
                  <div key={index} className={styles.addressContainer}>
                    <p>
                      {address.address1}
                      {address.address2 && `, ${address.address2}`}
                    </p>
                    <p>
                      {address.city}, {address.province}
                    </p>
                    <p>Zip Code: {address.zip}</p>
                    <p>{address.country}</p>
                  </div>
                ))
              ) : (
                <p>No shipping address available</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
