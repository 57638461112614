import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import styles from "./Index.module.sass";
import Card from "../../components/Card";
import Form from "../../components/Form";
import Panel from "./Panel";
import OrderLineItems from "./OrderLineItems/index";
import { message } from "antd";
import {
  FETCH_ORDER_DETAILS,
  GET_CAMERAS,
  GET_ALL_CAMERA_SETTINGS,
  GENERATE_OREDER_MEDIA_PRESIGNEDURL,
  UPLOAD_ORDER_MEDIA_METADATA,
  DELETE_ALL_ORDER_MEDIA,
  DELETE_SINGELORDER_MEDIA,
  PACK_ORDERS,
} from "../../API/Scanner";
import { GET_EMPLOYEE } from "../../API/Employee";
import Product from "./Product";
import Swal from "sweetalert2";
import Record from "../../assets/images/record.png";
import CustomToast from "../../components/CustomToast";
import Loader from "../../components/Loader";
import Image from "../../components/Image";

const ScanOrderIndex = () => {
  const showToast = CustomToast();
  const [searchInput, setSearchInput] = useState("");
  const [currentOrder, setCurrentOrder] = useState(null);
  const [cameras, setCameras] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [capturedPhotos, setCapturedPhotos] = useState([]);
  const [recordedVideos, setRecordedVideos] = useState([]);
  const [activeRecordings, setActiveRecordings] = useState(new Set());
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [scannedBarcode, setScannedBarcode] = useState("");
  const [newcapturedPhotos, setNewCapturedPhotos] = useState([]);
  const [activeBarcode, setActiveBarcode] = useState(null);
  const [canSearchNewOrder, setCanSearchNewOrder] = useState(true);
  const [employee, setEmployee] = useState(null);
  const videoRefs = useRef([]);
  const mediaRecorder = useRef([]);
  const chunks = useRef({});
  const [cameraStreams, setCameraStreams] = useState({});
  const [mediaUploaded, setMediaUploaded] = useState(false);
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const data = await GET_EMPLOYEE();
        setEmployee(data);
      } catch (error) {
        console.error("Failed to fetch employees:", error);
      }
    };

    fetchEmployees();
  }, []);

  useEffect(() => {
    const fetchEmployeeAndInitializeCameras = async () => {
      try {
        const employeeData = await GET_EMPLOYEE();
        setEmployee(employeeData);

        const fetchCamerasAndInitialize = async () => {
          try {
            let storedCameras = JSON.parse(
              localStorage.getItem("Camera") || "[]"
            );
            if (storedCameras.length === 0) {
              const camerasData = await GET_CAMERAS();
              storedCameras = camerasData;
              localStorage.setItem("Camera", JSON.stringify(camerasData));
            }
            setCameras(storedCameras);
            initializeCameras(storedCameras);
          } catch (error) {
            console.error("Error fetching or initializing cameras:", error);
            showToast("Failed to load camera information", "error");
          }
        };

        await fetchCamerasAndInitialize();
      } catch (error) {
        console.error("Failed to fetch employee data:", error);
        showToast("Failed to load employee data", "error");
      }
    };

    fetchEmployeeAndInitializeCameras();

    return () => {
      Object.values(cameraStreams).forEach((stream) => {
        stream.getTracks().forEach((track) => track.stop());
      });
    };
  }, []);

  const initializeCameras = async (camerasToInitialize) => {
    try {
      const streams = {};
      for (const camera of camerasToInitialize) {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: { exact: camera.deviceId } },
        });
        streams[camera.deviceId] = stream;
      }
      setCameraStreams(streams);
    } catch (error) {
      console.error("Error initializing camera streams:", error);
      showToast("Failed to initialize camera streams", "error");
    }
  };

  useEffect(() => {
    Object.entries(cameraStreams).forEach(([deviceId, stream]) => {
      const index = cameras.findIndex((camera) => camera.deviceId === deviceId);
      if (index !== -1 && videoRefs.current[index]) {
        videoRefs.current[index].srcObject = stream;
        videoRefs.current[index]
          .play()
          .catch((e) => console.error("Error playing video:", e));
      }
    });
  }, [cameraStreams, cameras]);

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      if (canSearchNewOrder) {
        const response = await FETCH_ORDER_DETAILS(searchInput);
        if (response.status === 200) {
          const orderData = response.data.data;
          setCurrentOrder(orderData);
          setSelectedProducts([]);
          setCanSearchNewOrder(false);
          showToast("Order fetched successfully", "success");
        } else {
          showToast("Order not found", "error");
        }
      } else {
        if (/^BAR_\d{3}$/.test(searchInput)) {
          await handleCameraMode(searchInput);
        } else {
          handleSKUScan(searchInput);
        }
      }
    } catch (error) {
      console.error("Error in handleSearch:", error);
      showToast("An error occurred", "error");
    } finally {
      setLoading(false);
      setSearchInput("");
    }
  };

  const handleSKUScan = (sku) => {
    const matchingSku = currentOrder.LineItems.find(
      (item) => item.sku === sku || item.barcode === sku
    );
    if (matchingSku) {
      setScannedBarcode(sku);
      showToast(`Scanned SKU: ${matchingSku.name}`, "success");
      setTimeout(() => {
        setScannedBarcode("");
      }, 100);
    } else {
      showToast("SKU is not matching", "error");
    }
  };
  const handleCameraMode = async (barcode) => {
    if (activeBarcode && barcode !== activeBarcode) {
      showToast(
        "Cannot start new recording while another is in progress",
        "error"
      );
      return;
    }

    try {
      const cameraResponse = await GET_ALL_CAMERA_SETTINGS();
      if (cameraResponse.status === 200) {
        const camerasData = cameraResponse.data.data;
        let foundCameras =
          camerasData.find((setting) => setting.barcode_value === barcode)
            ?.cameras || [];

        if (foundCameras.length > 0) {
          if (activeBarcode === barcode) {
            await stopAllRecordings();
            setActiveBarcode(null);
            showToast(`Stopped recording for barcode: ${barcode}`, "success");
          } else {
            const camerasToStart = [];

            for (let backendCamera of foundCameras) {
              const localCamera = cameras.find(
                (c) => c.id === backendCamera.id
              );
              if (!localCamera) {
                continue;
              }

              if (
                backendCamera.mode === "BOTH" ||
                backendCamera.mode === "PHOTO"
              ) {
                capturePhoto([localCamera.deviceId]);
              }
              if (
                backendCamera.mode === "BOTH" ||
                backendCamera.mode === "VIDEO"
              ) {
                camerasToStart.push(localCamera.deviceId);
              }
            }
            if (camerasToStart.length > 0) {
              startRecording(camerasToStart);
            }

            setActiveBarcode(barcode);
            message.success(`Camera mode activated for barcode: ${barcode}`);
          }
        } else {
          showToast(`No cameras found for barcode: ${barcode}`, "error");
        }
      } else {
        setError(
          cameraResponse.data.message || "Error fetching camera settings"
        );
      }
    } catch (err) {
      console.error("Error in handleCameraMode:", err);
      setError("Network error fetching camera settings");
    }
  };

  const stopAllRecordings = async () => {
    const activeDevices = Array.from(activeRecordings);
    await stopRecording(activeDevices);
  };

  const capturePhoto = (cameraIds = []) => {
    cameras.forEach((camera, index) => {
      if (
        videoRefs.current[index] &&
        (cameraIds.length === 0 || cameraIds.includes(camera.deviceId))
      ) {
        const canvas = document.createElement("canvas");
        canvas.width = videoRefs.current[index].videoWidth;
        canvas.height = videoRefs.current[index].videoHeight;

        const context = canvas.getContext("2d");
        context.drawImage(
          videoRefs.current[index],
          0,
          0,
          canvas.width,
          canvas.height
        );

        context.font = "bold 16px Arial";
        context.fillStyle = "#FFFFFF";
        context.textAlign = "left";
        context.fillText(` By : ${employee.full_name}`, 10, canvas.height - 10);

        const currentDate = new Date();
        const currentTimestamp = `${currentDate.getDate()}-${
          currentDate.getMonth() + 1
        }-${currentDate.getFullYear()} ${currentDate.getHours()}:${currentDate.getMinutes()} ${
          currentDate.getHours() >= 12 ? "PM" : "AM"
        }`;

        context.textAlign = "right";
        context.fillText(
          `Time : ${currentTimestamp}`,
          canvas.width - 10,
          canvas.height - 10
        );

        canvas.toBlob((blob) => {
          if (blob) {
            const newPhoto = {
              cameraId: camera.deviceId,
              imageBlob: blob,
              imageUrl: URL.createObjectURL(blob),
            };
            setCapturedPhotos((prevPhotos) => [...prevPhotos, newPhoto]);

            uploadMedia([
              {
                cameraId: camera.deviceId,
                type: "image",
                blob: blob,
                fileName: `ppg`,
              },
            ]);
          } else {
            console.error(`Failed to capture photo for camera: ${camera.name}`);
          }
        }, "image/png");
      }
    });
  };
  const startRecording = (cameraIds = []) => {
    cameras.forEach((camera, index) => {
      if (
        videoRefs.current[index] &&
        (cameraIds.length === 0 || cameraIds.includes(camera.deviceId))
      ) {
        const stream = videoRefs.current[index].srcObject;
        if (stream && stream.active) {
          if (!mediaRecorder.current[index]) {
            mediaRecorder.current[index] = new MediaRecorder(stream);
            chunks.current[camera.deviceId] = [];

            mediaRecorder.current[index].ondataavailable = (e) => {
              chunks.current[camera.deviceId].push(e.data);
            };

            mediaRecorder.current[index].start();

            setActiveRecordings((prev) => new Set(prev).add(camera.deviceId));
          }
        }
      }
    });
  };

  const stopRecording = async (cameraIds = []) => {
    const stoppingPromises = [];

    cameras.forEach((camera, index) => {
      if (
        videoRefs.current[index] &&
        (cameraIds.length === 0 || cameraIds.includes(camera.deviceId))
      ) {
        if (
          mediaRecorder.current[index] &&
          mediaRecorder.current[index].state === "recording"
        ) {
          mediaRecorder.current[index].stop();

          stoppingPromises.push(
            new Promise((resolve) => {
              mediaRecorder.current[index].onstop = () => {
                const blob = new Blob(chunks.current[camera.deviceId], {
                  type: "video/webm",
                });
                resolve({ cameraId: camera.deviceId, videoBlob: blob });
              };
            })
          );

          mediaRecorder.current[index] = null;
        }
      }
    });

    const newRecordedVideos = await Promise.all(stoppingPromises);
    setRecordedVideos((prevVideos) => [...prevVideos, ...newRecordedVideos]);
    setActiveRecordings(new Set());

    const mediaData = newRecordedVideos.map((video) => ({
      cameraId: video.cameraId,
      type: "video",
      blob: video.videoBlob,
      fileName: `ppg`,
    }));

    await uploadMedia(mediaData);
  };

  const uploadMedia = async (mediaData) => {
    try {
      setLoading(true);
      const response = await GENERATE_OREDER_MEDIA_PRESIGNEDURL(mediaData);
      const urls = response.data;

      const uploadPromises = mediaData.map(async (media, index) => {
        const { signedUrl } = urls[index];
        try {
          await fetch(signedUrl, {
            method: "PUT",
            headers: {
              "Content-Type": media.blob.type,
            },
            body: media.blob,
          });

          const mediaMetadata = {
            order_id: currentOrder.id,
            media: {
              type: media.type,
              url: urls[index].fileUrl,
            },
          };

          await UPLOAD_ORDER_MEDIA_METADATA(mediaMetadata);

          return mediaMetadata;
        } catch (error) {
          console.error(
            `Error uploading media ${index} to presigned URL:`,
            error
          );
          throw new Error(`Failed to upload media ${index} to presigned URL`);
        }
      });

      const uploadedMediaMetadata = await Promise.all(uploadPromises);

      showToast("Media uploaded successfully", "success");
      setMediaUploaded(true);
    } catch (error) {
      console.error("Error uploading media:", error);
      showToast("Failed to upload media", "error");
    } finally {
      setLoading(false);
    }
  };

  const markAsPacked = async () => {
    try {
      setLoading(true);
      const requestBody = {
        order_id: currentOrder.id,
        products: selectedProducts,
      };

      const response = await PACK_ORDERS(requestBody);

      if (response.status === 200) {
        setCanSearchNewOrder(true);
        setCapturedPhotos([]);
        setNewCapturedPhotos([]);
        setRecordedVideos([]);
        setCurrentOrder(null);
        setSearchInput("");
        setSelectedProducts([]);
        setScannedBarcode("");
        setActiveBarcode(null);

        Swal.fire({
          icon: "success",
          title: "Order Packed",
          text: "Order marked as packed successfully.",
          confirmButtonColor: "#3085d6",
        });
      } else {
        throw new Error(response.data.message || "Error packing order");
      }
    } catch (error) {
      console.error("Error in markAsPacked:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "There was an error marking the order as packed. Please try again.",
        confirmButtonColor: "#3085d6",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveAll = async () => {
    const confirmRemoveAll = await Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This action will remove all media associated with this order. This cannot be undone.",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove all media!",
    });

    if (confirmRemoveAll.isConfirmed) {
      try {
        const response = await DELETE_ALL_ORDER_MEDIA(currentOrder.id);

        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            text: "All media removed successfully.",
          });
          setCurrentOrder((prevData) => ({
            ...prevData,
            video_count: 0,
            image_count: 0,
            OrderMedia: [],
          }));
        } else {
          Swal.fire({
            icon: "error",
            text: response.data.message || "Error removing media",
          });
        }
      } catch (err) {
        console.log(err);
        Swal.fire({
          icon: "error",
          text: "An error occurred while removing media.",
        });
      }
    }
  };

  const handleDelete = async (mediaId) => {
    const confirmDelete = await Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This action cannot be undone.",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmDelete.isConfirmed) {
      try {
        const response = await DELETE_SINGELORDER_MEDIA(mediaId);

        if (response.status === 200) {
          setCurrentOrder((prevOrder) => ({
            ...prevOrder,
            OrderMedia: prevOrder.OrderMedia.filter(
              (item) => item.id !== mediaId
            ),
          }));

          Swal.fire({
            icon: "success",
            text: "Media deleted successfully.",
          });

          const updatedOrderResponse = await FETCH_ORDER_DETAILS(
            currentOrder.order_numberF
          );
          if (updatedOrderResponse.status === 200) {
            setCurrentOrder(updatedOrderResponse.data.data);
          }
        } else {
          Swal.fire({
            icon: "error",
            text: response.data.message || "Error deleting media",
          });
        }
      } catch (err) {
        Swal.fire({
          icon: "error",
          text: "Network error deleting media",
        });
      }
    }
  };

  const handleManualCapture = (selectedCameras) => {
    capturePhoto(selectedCameras);
  };

  const handleManualRecording = (selectedCameras) => {
    if (activeRecordings.size > 0) {
      stopRecording(Array.from(activeRecordings));
    } else {
      startRecording(selectedCameras);
    }
  };
  return (
    <>
      {loading && <Loader className={styles.loader} />}
      <div className={styles.section} style={{ marginBottom: "120px" }}>
        <div className={styles.row}>
          <div className={styles.col}>
            <>
              <OrderLineItems
                orderData={currentOrder}
                setCurrentOrder={setCurrentOrder}
                onSelectionChange={(selectedProducts) => {
                  setSelectedProducts(selectedProducts);
                }}
                scannedBarcode={scannedBarcode}
              />
            </>
          </div>
          <div className={styles.col}>
            <Card
              className={cn(styles.card)}
              title={currentOrder?.name ? `${currentOrder?.name}` : "Orders"}
              classTitle={cn("title-purple", styles.title)}
              classCardHead={cn(styles.head)}
              head={
                <>
                  <Form
                    className={styles.form}
                    value={searchInput}
                    setValue={setSearchInput}
                    placeholder={
                      canSearchNewOrder
                        ? "Scan Order Number"
                        : " SKU ,Camera Mode"
                    }
                    type="text"
                    name="search"
                    icon="search"
                    autoFocus={true}
                    onSubmit={handleSearch}
                  />
                  {activeRecordings.size > 0 && (
                    <Image
                      className="h-[25px] w-[60px]"
                      src={Record}
                      alt="Record"
                    />
                  )}
                </>
              }
            >
              <div className="flex flex-wrap gap-[15px]">
                {cameras.map((camera, index) => (
                  <div key={camera.id}>
                    <video
                      ref={(el) => (videoRefs.current[index] = el)}
                      width="270"
                      height="270"
                      autoPlay
                      muted
                      style={{ flex: "1 1 120px", borderRadius: "12px" }}
                    />
                    <p className="mt-[15px]">{camera.name}</p>
                  </div>
                ))}
              </div>
              {currentOrder && (
                <div className="mt-[50px]">
                  <Product
                    initialOrderMedia={currentOrder.OrderMedia}
                    orderNumber={currentOrder.order_number}
                    handleRemoveAll={handleRemoveAll}
                    handleDelete={handleDelete}
                    mediaUploaded={mediaUploaded}
                    setMediaUploaded={setMediaUploaded}
                  />
                </div>
              )}
            </Card>
          </div>
        </div>
        {currentOrder && (
          <div className="fixed bottom-7 left-30 right-5 ">
            <Panel
              cameras={cameras}
              handleCapture={capturePhoto}
              handleRecording={handleManualRecording}
              activeRecordings={activeRecordings}
              markAsPacked={markAsPacked}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ScanOrderIndex;
