import React from "react";
import AccessControl from "../../../HOC/AccessControl";
import cn from "classnames";
import styles from "./Panel.module.sass";
import Icon from "../../../components/Icon";
import { useFormikContext } from "../context/SettingsContext";
import { useReactToPrint } from "react-to-print";
const Panel = ({ activeTab, subActiveTab, setSubActiveTab }) => {
  const { formikRef, deleteRef, printComponentRef } = useFormikContext();

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    documentTitle: "Barcode",
    pageStyle: "@page { size: A4; margin: 20mm; }",
  });

  const handleSubmit = async () => {
    if (formikRef.current) {
      try {
        await formikRef.current.submitForm(); // Trigger form submission using Formik ref
      } catch (error) {
        console.error("Error submitting form: ", error);
      }
    }
  };
  const handleDelete = () => {
    if (deleteRef.current) {
      deleteRef.current(); // Trigger the delete function from context
    }
  };
  const renderButtons = () => {
    if (subActiveTab === 1 && activeTab == "Camera") {
      return (
        <AccessControl moduleCode="gsZHRE" requiredPrivilege="EhzFXx">
          <div className={styles.btns}>
            <button
              className={cn("button", styles.button)}
              onClick={handleSubmit}
            >
              Add Camera
            </button>
          </div>
        </AccessControl>
      );
    } else if (subActiveTab === 2 && activeTab == "Camera") {
      return (
        <AccessControl moduleCode="gsZHRE" requiredPrivilege="EhzFXx">
          <div className={styles.btns}>
            <button
              className={cn("button", styles.button)}
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </AccessControl>
      );
    } else if (subActiveTab === 3 && activeTab == "Camera") {
      return (
        <div className={styles.btns}>
          <AccessControl moduleCode="gsZHRE" requiredPrivilege="w8WFi0">
            <button
              className={cn("button-stroke", styles.button)}
              onClick={handleDelete}
            >
              <Icon name="trash" size="24" />
              <span>Delete</span>
            </button>
          </AccessControl>
          <AccessControl moduleCode="gsZHRE" requiredPrivilege="gM4UWp">
            <button
              className={cn("button", styles.button)}
              onClick={handleSubmit}
            >
              Save
            </button>
          </AccessControl>
        </div>
      );
    } else if (subActiveTab === 1 && activeTab == "Barcode") {
      return (
        <div className={styles.btns}>
          <AccessControl moduleCode="FvrVqN" requiredPrivilege="cifHqu">
            <button
              className={cn("button-white", styles.button)}
              onClick={handlePrint}
            >
              Download All
            </button>
          </AccessControl>
          <AccessControl moduleCode="FvrVqN" requiredPrivilege="cifHqu">
            <button
              className={cn("button", styles.button)}
              onClick={handleSubmit}
            >
              Add Barcode
            </button>
          </AccessControl>
        </div>
      );
    } else if (subActiveTab === 2 && activeTab == "Barcode") {
      return (
        <AccessControl moduleCode="FvrVqN" requiredPrivilege="cifHqu">
          <div className={styles.btns}>
            <button
              className={cn("button", styles.button)}
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </AccessControl>
      );
    } else if (subActiveTab === 3 && activeTab == "Barcode") {
      return (
        <div className={styles.btns}>
          <AccessControl moduleCode="FvrVqN" requiredPrivilege="5hGE0R">
            <button
              className={cn("button-stroke", styles.button)}
              onClick={handleDelete}
            >
              <Icon name="trash" size="24" />
              <span>Delete All</span>
            </button>
          </AccessControl>
          <AccessControl moduleCode="FvrVqN" requiredPrivilege="Djddd4">
            <button
              className={cn("button", styles.button)}
              onClick={handleSubmit}
            >
              Save
            </button>
          </AccessControl>
        </div>
      );
    } else if (subActiveTab === 1 && activeTab == "Roles") {
      return (
        <AccessControl moduleCode="BjQPiy" requiredPrivilege="7yBdYE">
          <div className={styles.btns}>
            <button
              className={cn("button", styles.button)}
              onClick={handleSubmit}
            >
              Create Role
            </button>
          </div>
        </AccessControl>
      );
    } else if (subActiveTab === 2 && activeTab == "Roles") {
      return (
        <div className={styles.btns}>
          <button
            className={cn("button", styles.button)}
            onClick={handleSubmit}
          >
            Save & Continue
          </button>
        </div>
      );
    } else if (subActiveTab === 4 && activeTab == "Roles") {
      return (
        <div className={styles.btns}>
          <button
            className={cn("button", styles.button)}
            onClick={handleSubmit}
          >
            Save & Continue
          </button>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={cn("panel", styles.panel)}>
      {/* <div className={styles.info}>
        <Icon name="check-all" size="24" />2 products selected
      </div> */}

      {renderButtons()}
    </div>
  );
};

export default Panel;
