import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Entry.module.sass";
import TextInput from "../../../components/TextInput";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { signUp, reset } from "../../../features/auth/authSlice";
import { useLocation } from "react-router-dom";
import Loader from "../../../components/Loader";

import { logo_presigned_url } from "../../../API/Auth";

import store_dark_image from "../../../assets/images/store_logo_dark.svg";
import store_light_image from "../../../assets/images/store_logo_light.svg";

import Image from "../../../components/Image";
import CustomToast from "../../../components/CustomToast";
// SweetAlert setup

const Entry = ({ onConfirm, setUserData }) => {
  const showToast = CustomToast();
  const { isSuccess, message, isLoading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();

  const [previewImage, setPreviewImage] = useState(null);

  const getQueryParams = (search) => new URLSearchParams(search);
  const queryParams = getQueryParams(location.search);
  const storeId = queryParams.get("store_id");

  const initialValues = {
    email: "",
    full_name: "",
    logo: null, // Adding a field for the image file
  };

  const validationSchema = Yup.object({
    email: Yup.string().required("Email is required"),
    full_name: Yup.string().required("Full name is required"),
    logo: Yup.mixed().required("Logo is required"),
  });

  const handleSignUp = async (
    values,
    { setTouched, setErrors, validateForm }
  ) => {
    try {
      const errors = await validateForm();
      if (Object.keys(errors).length > 0) {
        setTouched({
          name: true,
        });
        setErrors(errors);
        return;
      }

      const formData = {
        email: values.email,
        token: storeId,

        full_name: values.full_name,
        logo: values.logo, // Passing logo (file) in form data
      };
      const fileName = values.logo.name; // Get the file name
      const fileExtension = fileName.split(".").pop().toLowerCase(); // Extract the file extension

      const url_data = {
        name: fileName,
        type: fileExtension,
      };
      const logo_pre_sign = await logo_presigned_url(url_data);

      dispatch(signUp(formData));

      setUserData({
        full_name: values.full_name,
        email: values.email,
        token: storeId,
        logo: values.logo,
        logo_signed_url: logo_pre_sign.data,
      });
    } catch (error) {
      // Handle errors
    }
  };

  const handleImageChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    if (file) {
      setPreviewImage(URL.createObjectURL(file));
      setFieldValue("logo", file); // Store file in Formik's `logo` field
    }
  };

  const triggerFileInput = () => {
    document.getElementById("fileInput").click();
  };

  useEffect(() => {
    if (isSuccess) {
      showToast(`OTP sent to your email address`, "success");

      onConfirm();
    } else if (message) {
      showToast(message.data.err, "error");
    }
    dispatch(reset());
  }, [isSuccess, message]);

  return (
    <div className={styles.entry}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSignUp}
      >
        {({ setFieldValue, errors }) => (
          <Form>
            <div className={styles.body}>
              <div className={styles.fileUpload}>
                <input
                  id="fileInput"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => handleImageChange(e, setFieldValue)}
                />
              </div>
              <div className={styles.logo_holder}>
                {previewImage ? (
                  <div className={styles.logo_img}>
                    <img
                      src={previewImage}
                      alt="Selected"
                      style={{ width: "150px" }}
                    />
                  </div>
                ) : (
                  <Image
                    src={store_light_image}
                    // srcDark="/images/logo-light.png"
                    srcDark={store_dark_image}
                    alt="Core"
                    className={styles.store_logo}
                  />
                )}
                <button
                  type="button"
                  className={cn("button-stroke")}
                  onClick={triggerFileInput}
                >
                  Select Store Logo
                </button>
                {errors.logo && (
                  <div className={styles.error}>{errors.logo}</div>
                )}
              </div>
              <Field name="email">
                {({ field, meta }) => (
                  <TextInput
                    {...field}
                    className={styles.field}
                    type="email"
                    placeholder="Your email"
                    required
                    icon="mail"
                    error={meta.touched && meta.error ? meta.error : ""}
                  />
                )}
              </Field>

              <Field name="full_name">
                {({ field, meta }) => (
                  <TextInput
                    {...field}
                    className={styles.field}
                    type="text"
                    placeholder="Full Name"
                    required
                    icon="profile-circle"
                    error={meta.touched && meta.error ? meta.error : ""}
                  />
                )}
              </Field>

              <button className={cn("button", styles.button)} type="submit">
                {isLoading ? <Loader /> : "Continue"}
              </button>
              <div className={styles.note}>
                This site is protected by reCAPTCHA and the Google Privacy
                Policy.
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Entry;
